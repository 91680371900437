import React, { useState, useEffect } from "react"
import { useQueryParam, NumberParam } from "use-query-params"
import { getQueryVariable } from "../lib/util"
import { navigate } from "gatsby"
import Layout from "../zzz/layout"
import Checkout from "../components/Checkout/Checkout"
import { isInStoreFrontend } from "../services/auth"

const OrderStatusPage = ({ location }) => {
  const orderStatusInStoreFrontend = isInStoreFrontend()
  const [orderId, setOrderId] = useQueryParam("orderid", NumberParam)

  // Bad order ID given. Let's just go to customer My Account
  if (typeof window != "undefined" && isNaN(parseInt(orderId))) {
    navigate("/my-account/?&o")
  }

  return (
    <Layout location={location}>
      <Checkout
        location={location}
        givenOrderId={orderId}
        givenTabIdx={3}
        orderStatusInStoreFrontend={orderStatusInStoreFrontend}
      />
    </Layout>
  )
}

export default OrderStatusPage
